.App {
  min-width: 1200px;
  overflow-x: scroll;
  height: 100vh;
  overflow: hidden;
}

:focus {
  outline: none !important;
  outline-offset: 0px !important;
}
